import React, { useEffect, useRef, useState } from 'react'

import {
  Anchor,
  Avatar,
  Box,
  Group,
  Image,
  ScrollArea,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core'
import {
  IconAlertCircle,
  IconClock,
  IconLoader,
  IconTargetArrow,
} from '@tabler/icons-react'
import {
  GetCustomerChatQuery,
  GetCustomerChatQueryVariables,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import SendMessageForm from 'src/components/acquisition/chat/SendMessageForm'
import {
  GET_CUSTOMER_CHAT_QUERY,
  RESEND_MESSAGE_MUTATION,
} from 'src/graphql/acquisition/lead-management'
import { dayjs, formatDate, formatDateRelative } from 'src/lib/date'

import { FormattedMessage } from './FormattedMessage/FormattedMessage'

type Message = GetCustomerChatQuery['GetCustomerChat']['messages'][number]

type ChatProps = {
  initialMessages?: Message[]
  customerId: number
}

const MarketingMessage = ({ message }: { message: Message }) => {
  return (
    <Group key={message.id} align="flex-end" justify="flex-end" gap="sm">
      <Stack gap={0} align="flex-end" flex={1}>
        <Group justify="flex-end" gap="xs" wrap="nowrap">
          <Stack gap={2} align="flex-end">
            <Box
              px={10}
              py={8}
              maw="60%"
              style={(theme) => ({
                backgroundColor: theme.colors.violet[6],
                color: 'white',
                wordWrap: 'break-word',
                alignSelf: 'flex-end',
                borderRadius: 8,
              })}
            >
              <Group gap="xs">
                {/* Consider line breaks with styling */}
                <Text
                  size="sm"
                  ta="left"
                  fs="italic"
                  style={{ whiteSpace: 'pre-line' }}
                >
                  <FormattedMessage message={message.content} />
                </Text>
              </Group>
            </Box>

            <Tooltip label={formatDate(message.createdAt)} fz="xs">
              <Text fz={10} c="dimmed">
                {dayjs(message.createdAt).isSame(dayjs(), 'day')
                  ? formatDateRelative(message.createdAt)
                  : formatDate(message.createdAt)}
              </Text>
            </Tooltip>
          </Stack>

          <Tooltip label="Mensagem de marketing enviada automaticamente">
            <Avatar
              color="initials"
              size="sm"
              radius="xl"
              variant="outline"
              name="CNA"
              style={{
                alignSelf: 'flex-end',
              }}
            >
              <IconTargetArrow size={16} />
            </Avatar>
          </Tooltip>
        </Group>
      </Stack>
    </Group>
  )
}

const ConsultantMessage = ({ message }: { message: Message }) => {
  const [resendMessage, { loading: resendMessageLoading }] = useMutation(
    RESEND_MESSAGE_MUTATION
  )
  const didMessageFail = message.deliveryStatus === 'ERROR'

  return (
    <Group key={message.id} align="flex-end" justify="flex-end" gap="sm">
      <Stack gap={0} align="flex-end" flex={1}>
        <Text fw={500} size="xs" c="dark.3">
          {message.sender.name}
        </Text>
        <Box
          px={10}
          py={8}
          my={4}
          maw="60%"
          bd={didMessageFail ? '1px dashed var(--mantine-color-red-6)' : 'none'}
          style={(theme) => ({
            backgroundColor: didMessageFail
              ? theme.colors.white
              : theme.colors.blue[6],
            color: didMessageFail ? theme.colors.red[6] : 'white',
            wordWrap: 'break-word',
            alignSelf: 'flex-end',
            borderRadius: 8,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 8,
          })}
        >
          <Text size="sm" ta="left" style={{ whiteSpace: 'pre-line' }}>
            <FormattedMessage message={message.content} />
          </Text>
        </Box>

        {message.deliveryStatus === 'PENDING' && (
          <Tooltip label="Enviando mensagem" fz="xs">
            <IconClock size={12} color="var(--mantine-color-gray-6)" />
          </Tooltip>
        )}

        {message.deliveryStatus === 'ERROR' && (
          <>
            {resendMessageLoading && (
              <Tooltip label="Tentando enviar novamente" fz="xs">
                <Group gap={5}>
                  <IconLoader size={16} color="var(--mantine-color-red-6)" />
                </Group>
              </Tooltip>
            )}

            {!resendMessageLoading && (
              <Tooltip label="Clique para enviar novamente" fz="xs">
                <Group gap={5}>
                  <Anchor
                    size="xs"
                    c="red.6"
                    td="underline"
                    onClick={() =>
                      resendMessage({ variables: { messageId: message.id } })
                    }
                  >
                    Erro ao enviar. Tentar novamente?
                  </Anchor>

                  <IconAlertCircle
                    size={16}
                    color="var(--mantine-color-red-6)"
                  />
                </Group>
              </Tooltip>
            )}
          </>
        )}

        <Tooltip label={formatDate(message.createdAt)} fz="xs">
          <Text fz={10} c="dimmed">
            {dayjs(message.createdAt).isSame(dayjs(), 'day')
              ? formatDateRelative(message.createdAt)
              : formatDate(message.createdAt)}
          </Text>
        </Tooltip>
      </Stack>

      <Avatar
        color="initials"
        size="sm"
        radius="xl"
        variant="outline"
        name={message.sender.name}
      />
    </Group>
  )
}

const CustomerMessage = ({ message }: { message: Message }) => {
  return (
    <Group key={message.id} align="flex-end" justify="left" gap="sm">
      <Stack gap={0} align="flex-start" flex={1}>
        <Text fw={500} size="xs" c="dark.3" lineClamp={1} maw={300}>
          {message.sender.name}
        </Text>

        <Box
          px={10}
          py={8}
          my={4}
          maw="60%"
          style={(theme) => ({
            backgroundColor: theme.colors.gray[3],
            color: theme.black,
            wordWrap: 'break-word',
            alignSelf: 'flex-start',
            borderRadius: 8,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 0,
          })}
        >
          {message.contentType === 'AUDIO' && (
            <audio src={message.mediaUrl} controls />
          )}

          {message.contentType === 'IMAGE' && (
            <Tooltip label="Clique para ver a imagem">
              <Anchor href={message.mediaUrl} target="_blank">
                <Image src={message.mediaUrl} alt="Mensagem de cliente" />
              </Anchor>
            </Tooltip>
          )}

          {message.content?.length > 0 && (
            <Text size="sm" ta="left" style={{ whiteSpace: 'pre-line' }}>
              <FormattedMessage message={message.content} />
            </Text>
          )}
        </Box>

        <Tooltip label={formatDate(message.createdAt)} fz="xs">
          <Text fz={10} c="dimmed">
            {dayjs(message.createdAt).isSame(dayjs(), 'day')
              ? formatDateRelative(message.createdAt)
              : formatDate(message.createdAt)}
          </Text>
        </Tooltip>
      </Stack>
    </Group>
  )
}

export const Chat: React.FC<ChatProps> = ({
  initialMessages = [],
  customerId,
}) => {
  const [messages, setMessages] = useState<Message[]>(initialMessages)
  const viewport = useRef<HTMLDivElement>(null)

  const { data } = useQuery<
    GetCustomerChatQuery,
    GetCustomerChatQueryVariables
  >(GET_CUSTOMER_CHAT_QUERY, {
    variables: { customerId },
    pollInterval: 1000,
  })

  useEffect(() => {
    if (!data?.GetCustomerChat) return

    setMessages(data.GetCustomerChat.messages)
  }, [data])

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom()
    }
  }, [messages])

  const scrollToBottom = () =>
    viewport.current!.scrollTo({
      top: viewport.current!.scrollHeight,
      behavior: 'instant',
    })

  return (
    <Stack h="100%">
      <ScrollArea
        type="scroll"
        scrollbars="y"
        viewportRef={viewport}
        style={{ flex: 1 }}
        styles={{
          viewport: {
            alignContent: messages.length > 0 ? 'flex-end' : 'center',
          },
        }}
      >
        <Stack py={24} p="xs" justify="center">
          {messages.map((message) => (
            <>
              {message.sender.type === 'MARKETING' && (
                <MarketingMessage message={message as Message} />
              )}

              {message.sender.type === 'CONSULTANT' && (
                <ConsultantMessage message={message as Message} />
              )}

              {message.sender.type === 'CUSTOMER' && (
                <CustomerMessage message={message as Message} />
              )}
            </>
          ))}
          {messages.length === 0 && (
            <Text
              display="block"
              c="dark.1"
              size="sm"
              ta="center"
              style={{ alignSelf: 'center' }}
            >
              Sem mensagens
            </Text>
          )}
        </Stack>
      </ScrollArea>

      <SendMessageForm
        customerId={customerId}
        suggestions={data?.GetCustomerChat?.suggestions}
        canSendMessage={data?.GetCustomerChat?.canSendMessage}
        reasonCantSendMessage={data?.GetCustomerChat?.reasonCantSendMessage}
      />
    </Stack>
  )
}
