import { Button, Group } from '@mantine/core'
import { Lead } from 'types/graphql'
import type {
  AssignLeadMutation,
  AssignLeadMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { ASSIGN_LEAD_MUTATION } from 'src/graphql/acquisition/lead-management'

import CloseLeadAction from './CloseLeadAction'
import ReopenLeadAction from './ReopenLeadAction'

export const LeadActions = ({ lead }: { lead: Lead }) => {
  const { currentUser } = useAuth()

  const [assignLead, { loading }] = useMutation<
    AssignLeadMutation,
    AssignLeadMutationVariables
  >(ASSIGN_LEAD_MUTATION, {
    refetchQueries: [
      'ListLeadsByStatusQuery',
      'ListCustomerEventsQuery',
      'GetLeadQuery',
    ],
  })

  const assignToMyself = () => {
    assignLead({ variables: { input: { leadId: lead.id } } })
  }

  return (
    <>
      {currentUser.salesConsultantId === lead.owner?.id && (
        <Group gap="xs">
          {lead.status === 'IN_PROGRESS' && <CloseLeadAction lead={lead} />}
          {lead.status === 'FINISHED' && <ReopenLeadAction lead={lead} />}
        </Group>
      )}
      {lead.owner === null && (
        <Button
          size="compact-md"
          radius="sm"
          fz="xs"
          color="green"
          onClick={assignToMyself}
          loading={loading}
        >
          Atribuir a mim
        </Button>
      )}
    </>
  )
}
